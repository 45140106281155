import React, { Component } from "react";
import "./App.css";
import { STAGE } from './components/helpers/config'
// Import components
import Dashboard from "./components/Dashboard";

class App extends Component {
  render() {
    if (STAGE === "prod"){
      console.log = function (){};
    }
    return (
      <div className="App">
        <Dashboard/>
      </div>
    );
  }
}

export default App
