import CCP from "./ccp";
import NavComp from "./navcomp";
import ConnectQueue from "./queue";

const Dashboard = () => {
  return (
    <div className="Dashboard">
      <><NavComp /></>
      <><ConnectQueue /></>
      <><CCP /></>
    </div>
  );
}

export default Dashboard;
