import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const divQTitle = {
    textAlign: 'center',
    fontWeight: 'bolder',
};

export const divTitle = {
    textAlign: 'center',
    fontWeight: 'bolder',
    backgroundColor: '#cc0000',
    color: 'white',
};

export const divBTitle = {
    textAlign: 'center',
    fontWeight: 'bolder',
    backgroundColor: '#252424',
    color: 'white',
};

export const redbutton = {
    color: '#cc0000',
};

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip leaveDelay={50} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#cc0000',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const linkActive = {
    borderBottomStyle: "solid",
    borderBottomColor: "#cc0000",
    borderBottomWidth: "12px",
    color: "#cc0000"
  };

export const divRight = {
    borderRightStyle: "solid",
    borderRightColor: "#aeaeae78",
    color: "#666565",
};

export const queTitle = {
    textAlign: 'center',
    fontWeight: 'bolder',
    color: "black",
};

export const divText = {
    marginTop: "15px",
    textAlign: 'center',
};

export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: 800,
    borderRadius: '8px',
    p: 2,
  };

  export const dlstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: 700,
    borderRadius: '8px',
    p: 2,
  };