import { React, useState, useEffect, useCallback } from "react";

//Helpers
import { genLogger } from "./helpers/lib";
import { API_HOST, STAGE } from "./helpers/config";
import { useInterval } from "./helpers/hooks";

//UI Components
import { LightTooltip, divText, divRight, divQTitle, queTitle } from "./helpers/style";
import Autosuggest from "@awsui/components-react/autosuggest";
import Icon from "aws-northstar/components/Icon";
import './style.css'

const name = "Queue";
const { error } = genLogger(name);
const buildUrl = (queueId) => `${API_HOST}/${STAGE}/getConnectDetails?&queueId=${queueId}`;

const getConnectData = (queueId) =>
    fetch(buildUrl(queueId)).then((res) => res.json());

const ConnectQueue = () => {
    const [routingProfileQueues, setRoutingProfileQueues] = useState([]);
    const [selectedQueue, setSelectedQueue] = useState({});
    const [queueNameLookUp] = useState({});

    // fetch updates every second
    const date = useInterval(1000);
    const [connectDetails, setConnectDetails] = useState({});

    useEffect(() => {
        // state alternative
        let isCancelled = false;
            try {
                window.connect.agent(async (agent) => {
                    const { routingProfile } = agent.getConfiguration();
                    const { queues } = routingProfile;
                    const filteredQueues = queues
                        .filter((q) => q.name !== null)
                        .map((q) => {
                            queueNameLookUp[q.queueId] = q.name;
                            return { label: q.name, value: q.queueId };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label));
                   
                    setRoutingProfileQueues(filteredQueues);
                });

            } catch (e) {
                if (!isCancelled) error(e);
            }
        return () => (isCancelled = true);
    }, [date, queueNameLookUp]);

    async function fetchQueue(qname) {
        const data = await getConnectData(qname);
        setConnectDetails(data);
    }

    const onInputChange = useCallback(({ detail }) => {
        const quname = queueNameLookUp[detail.value];
        setSelectedQueue({ value: detail.value, label: quname });
        fetchQueue(detail.value);
        console.log( "CDEBUG ===> Queue Details" , fetchQueue(detail.value));
    }, [setSelectedQueue, queueNameLookUp]);

    const refershQ = () => {
        if (selectedQueue.label !== undefined)
            fetchQueue(selectedQueue.value);
        else
            fetchQueue();
    }

    const disabled = (!selectedQueue.label &
        !connectDetails.AGENTS_AVAILABLE &
        !connectDetails.AGENTS_ON_CONTACT &
        !connectDetails.CONTACTS_IN_QUEUE &
        !connectDetails.OLDEST_CONTACT_AGE &
        !connectDetails.AGENTS_NON_PRODUCTIVE &
        !connectDetails.AGENTS_AFTER_CONTACT_WORK);

    return (
        
        <div>
            <div style={{ margin: "40px" }}>
                <div className="row" style={{ marginTop: "20px" }}>
                    <LightTooltip title="Refresh" style={{ color: "#cc0000", fontWeight: "bolder" }}>
                        <div className="col-1" style={divRight}>
                            <div style={divText}>
                                <button id="refresh" disabled={disabled} className="btn" onClick={refershQ}><Icon name="Refresh" onClick={refershQ} /></button>
                            </div>
                        </div>
                    </LightTooltip>
                    <div className="col-2" style={divRight}>
                        <span style={queTitle}><b>Queue</b></span> &nbsp;
                        <br />
                        <Autosuggest
                            options={routingProfileQueues}
                            filteringType="manual"
                            placeholder="Select a queue"
                            enteredTextLabel={value => `Use: "${value}"`}
                            ariaDescribedby="additional intent selector"
                            onChange={onInputChange}
                            value={selectedQueue?.label}
                            finishedText="End of results"
                            errorText="Unable to fetch queues"
                            empty="No queues found"
                        />
                    </div>
                    <div className="col-2" style={divRight}>
                        <div style={divQTitle}>AGENTS AVAILABLE</div>
                        <div style={divText}>
                            <Icon name="HeadsetMic" /> { }
                            {connectDetails.AGENTS_AVAILABLE}
                        </div>
                    </div>
                    <div className="col-2" style={divRight}>
                        <div style={divQTitle}>AGENTS ON CONTACT</div>
                        <div style={divText}>
                            <Icon name="Call" /> { }
                            {connectDetails.AGENTS_ON_CONTACT}
                        </div>
                    </div>
                    <div className="col-2" style={divRight}>
                        <div style={divQTitle}>CUSTOMERS IN QUEUE</div>
                        <div style={divText}>
                            <Icon name="People" /> { }
                            {connectDetails.CONTACTS_IN_QUEUE}
                        </div>
                    </div>
                    <div className="col-1" style={divRight}>
                        <div style={divQTitle}>OLDEST</div>
                        <div style={divText}>
                            <Icon name="History" /> { }
                            {connectDetails.OLDEST_CONTACT_AGE}
                        </div>
                    </div>
                    <div className="col-1" style={divRight}>
                        <div style={divQTitle}>NPT</div>
                        <div style={divText}>
                            <Icon name="WatchLater" /> { }
                            {connectDetails.AGENTS_NON_PRODUCTIVE}
                        </div>
                    </div>
                    <div className="col-1" style={divRight}>
                        <div style={divQTitle}>ACW</div>
                        <div style={divText}>
                            <Icon name="CallEnd" /> { }
                            {connectDetails.AGENTS_AFTER_CONTACT_WORK}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ConnectQueue;
