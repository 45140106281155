import { React, useState, useEffect } from "react";
import * as yup from 'yup';
import { Formik, Form, Field} from 'formik';
import axios from "axios";

//Helpers
import { useInterval } from "./helpers/hooks";
import { genLogger } from "./helpers/lib";
import { FETCH_CHAT, DOWNLOAD_CHAT } from "./helpers/config";

//UI Components
import Icon from "aws-northstar/components/Icon";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import newLog from '../assets/logo.jpeg'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import ReactPaginate from 'react-paginate';
import './style.css'
import { LightTooltip, linkActive, dlstyle } from "./helpers/style";

const name = "Queue";
const { error } = genLogger(name);
const fetchChatFilesURL = FETCH_CHAT;
const fetchChatDownloadsURL = DOWNLOAD_CHAT;
const today = new Date() + 1;


const schema = yup.object().shape({
  dlDate: yup.date().required("Date is required").max(today, "Date cannot be in the future"),
})


function NavComp() {
  const date = useInterval(1000);
  const [open, setOpen] = useState(true);
  const [modal, showModal] = useState(false);
  const [agentName, setAgentName] = useState("Agent");
  const [fileView, setFileView] = useState(false);
  const [offset, setOffset] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [orgtableData, setOrgtableData] = useState([]);
  const [perPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [dataview, setDataview ] = useState("")

  const handleOpen = () => {
    showModal(true);
  };
  const handleClose = () => {
    showModal(false);
    setFileView(false)
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetValue = selectedPage * perPage;
    setOffset(offsetValue);
    loadMoreData(offsetValue);
  };


  const loadMoreData = (offsetValue) => {
    const data = orgtableData;
    const slice = data.slice(offsetValue, offsetValue + perPage)
    setTableData(slice);
  }

  const downloadFile = async (fileName) => {
    const response = await axios.get(fetchChatDownloadsURL + fileName);
    const jsonResponse = response.data.message;
    const contactId = response.data.contactId;
    const element = document.createElement("a");
    const file = new Blob([jsonResponse], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = contactId+ ".txt";
    document.body.appendChild(element); // Required to work in FireFox
    element.click();
  };

  const DataShow = async () => {
    let value = document.getElementById("searchDate").value;
    const response = await axios.get(fetchChatFilesURL + value);
    if (response.data.message.KeyCount > 0) {
      let fileArray = [];
      for (let start = 0; start < response.data.message.KeyCount; start++) {
        let fileName = {};
        fileName.key = response.data.message.Contents[start].Key;
        fileArray.push(fileName);
      }
      let slice = fileArray.slice(offset, offset + perPage)
      setPageCount(Math.ceil(fileArray.length / perPage))
      setOrgtableData(fileArray)
      setTableData(slice)
      setFileView(true)
      setDataview("")
    } else if (response.data.message.KeyCount < 1 && value.length > 0){
      setFileView(false)
      setDataview(<div style={{color:"#cc0000"}}><b>"No data found!"</b></div>)
      setTimeout(() => {setDataview("")}, 5000)
    } else{
      setFileView(false)
      setDataview("")
    }
  }


  useEffect(() => {
    let isCancelled = false;
      try {
        window.connect.agent(async (agent) => {
          const agentname  = agent.getConfiguration().name;
          setAgentName(agentname);
          setOpen(false);
        });
      } catch (e) {
        if (!isCancelled) error(e);
      }

    return () => (isCancelled = true);
  }, [date]);

  function stringAvatar(uname) {
    return {
      sx: {
        bgcolor: "#cc0000",
      },
      children: `${uname.split(' ')[0][0]}`,
    };
  }
  

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" /> &nbsp;
        Please Login into Amazon Connect
      </Backdrop>
      <nav className="navbar navbar-expand-md" id="navBar">
        <a className="navbar-brand" href="#home"><img src={newLog} alt='Toyota' width="30%" /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">

            <li className="nav-item" id="linkActive2">
              <LightTooltip title="Download Chat Transcripts" style={{ color: "#cc0000", fontWeight: "bolder" }}>
                <Button style={{ color: "white", marginTop: "10px" }} id="download" onClick={handleOpen}><Icon name="CloudDownload" /> &nbsp;Download</Button>
              </LightTooltip>
              <Modal
                open={modal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={dlstyle}>
                  <Formik
                    initialValues={{ dlDate: '' }}
                    validationSchema={schema}
                  >
                    {({ touched, errors }) =>
                      <div>
                        <div className="modal-header">
                          <h3 className="modal-title p-1">Download Chat Transcripts</h3>
                          <LightTooltip title="Close" style={{ color: "#cc0000", fontWeight: "bolder" }}>
                          <button type="button" className="close" data-dismiss="modal" onClick={handleClose}><Icon name="Cancel" fontSize="large" style={{ color: "red" }} /></button>
                          </LightTooltip>
                        </div>
                        { ({}) &&
                          !fileView ?
                            <Form>
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-3" style={{ marginLeft: "40px" }}><h4 className="p-2">Select Date </h4></div>
                                  <div className="col-md-6 p-2" >
                                    <div className="row">
                                      <div className="col-md-8">
                                        <Field
                                          id="searchDate"
                                          type="date"
                                          name="dlDate"
                                          placeholder="Select Date"
                                          className={`form-control
                                        ${touched.dlDate && errors.dlDate ? "is-invalid" : ""}
                                        `}
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <button type="submit" className="btn btn-success" data-dismiss="modal" onClick={DataShow}>Search</button>
                                      </div>
                                    </div>
                                    <div style={{ color: '#cc0000' }}>{errors.dlDate}</div>
                                  </div>
                                </div>
                              </div>
                              <div align="center">{dataview}</div>
                            </Form> :
                            <div>
                                <table className="table table-striped">
                                  <thead>
                                    <tr align="center">
                                      <td><b>SNo</b></td>
                                      <td><b>Chat Transaction</b></td>
                                      <td><b>Action</b></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tableData.map((value, i) => (
                                      <tr>
                                        <td align="center">{i + 1 + offset}</td>
                                        <td><small>{value.key.substring(value.key.lastIndexOf("/") + 1).replace(".json", "")}</small></td>
                                        <td><Button style={{ color: "white"}} id="downloadsm" onClick={() => downloadFile(value.key)}><small><Icon name="CloudDownload" />&nbsp;<b>Dowload</b></small></Button></td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  breakClassName={"break-me"}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={5}
                                  pageRangeDisplayed={5}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination"}
                                  subContainerClassName={"pages pagination"}
                                  activeClassName={"active"} />
                            </div>
                        }
                      </div>
                    }

                  </Formik>

                </Box>
              </Modal>
            </li>
            <li style={{ marginRight: "20px" }}>

            </li>
            <li className="nav-item" style={linkActive}>
              <a className="nav-link" href="#home" style={{ color: "#cc0000", fontWeight: "bolder" }}>DK LIVE AGENT</a>
            </li>
            <li style={{ marginRight: "20px" }}>

            </li>
            <li className="nav-item">
            <div className="nav-link" style={{marginTop: "3px", marginRight:"-12px"}}><Avatar sx={{ width: 12, height: 12 }} {...stringAvatar(`${agentName}`)}/></div>
            </li>
            <li className="nav-item">
              <div className="nav-link" style={{ color: "#cc0000", marginTop: "10px", fontWeight: "bolder" }}>{agentName}</div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default NavComp;
