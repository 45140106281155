export const API_HOST = process.env.REACT_APP_CONNECT_API_HOST;
export const STAGE = process.env.REACT_APP_STAGE;
export const FETCH_CHAT = `${API_HOST}/${STAGE}/getChatTransData?&selectedDate=`;
export const DOWNLOAD_CHAT = `${API_HOST}/${STAGE}/downloadChatTrans?&selectedFile=`;
export const TRANSLATEURI = `${API_HOST}/${STAGE}/autoTranslate`;
export const CONNECTURL = process.env.REACT_APP_CONNECT_INSTANCE_URL;
export const REGION = process.env.REACT_APP_CONNECT_REGION;





