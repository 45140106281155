import React, { useEffect, useState, useCallback } from 'react';
import Chatroom from './chatroom.jsx';
import { postTranslateText } from './helpers/translateAPI'
import {
  addChat,
  setLanguageTranslate,
  useGlobalState,
  setCurrentContactId,
} from './helpers/state';
import { CONNECTURL, REGION } from './helpers/config';
// *******
// Subscribe to the chat session
// *******


async function GetEvents(contact, languageTranslate, _agentChatSession) {
  const controller = await contact.getAgentConnection().getMediaController();
  let arr = [];

  controller.onMessage(async messageData => {
    if (messageData.chatDetails.participantId === messageData.data.ParticipantId) {
      console.log(`CDEBUG ===> Agent ${messageData.data.DisplayName} Says`,
        messageData.data.Content)
      arr.push(`CDEBUG ===> Agent ${messageData.data.DisplayName} Says ${messageData.data.Content}`)
      console.log(arr)
    }
    else {
      console.log(`CDEBUG ===> Customer ${messageData.data.DisplayName} Says`, messageData.data.Content);
      let msg = await processChatText(messageData.data.Content, messageData.data.Type, messageData.data.ContactId, languageTranslate)
      arr.push(`CDEBUG ===> Customer ${messageData.data.DisplayName} Says  ${messageData.data.Content} Translation: ${msg}`)
      console.log(arr)
    }
  })
}

// *******
// Processing the incoming chat from the Customer
// *******
async function processChatText(content, _type, contactId, languageTranslate) {
  // Check if we know the language for this contactId, if not use dectectText(). This process means we only perform comprehend language detection at most once.
  let textLang = languageTranslate[contactId];

  let translateResponse = {};
  try {
    // Translate the customer message into English.
    translateResponse = await postTranslateText(content, textLang, 'en');
  } catch (err) {
    console.error(err);
  }

  const payload = translateResponse?.payload || {};
  const { text, sourceLanguageCode } = payload;

  if (!textLang && sourceLanguageCode) {
    languageTranslate[contactId] = sourceLanguageCode;
    setLanguageTranslate(languageTranslate);
  }

  console.log(`CDEBUG ===>  Original Message: ` + content + `\n Translated Message: ` + text);


  // create the new message to add to Chats.
  let newMessageData = {
    contactId: contactId,
    username: 'customer',
    content: <p>{content}</p>,
    translatedMessage: <p>{text}</p>
  };
  // Add the new message to the store
  addChat(prevMsg => [...prevMsg, newMessageData]);
  return text
}

const CCP = () => {
  const [languageTranslate] = useGlobalState('languageTranslate');
  const [agentChatSessionState, setAgentChatSessionState] = useState([]);
  
  // *******
  // Subscribing to CCP events. See : https://github.com/aws/amazon-connect-streams/blob/master/Documentation.md
  // *******
  const subscribeConnectEvents = useCallback(() => {
   
    window.connect.core.onViewContact(function (event) {
      let contactId = event.contactId;
      console.log("CDEBUG ===> onViewContact", contactId)
      setCurrentContactId(contactId);
    });

    console.log("CDEBUG ===> subscribeConnectEvents");

    // If this is a chat session
    if (window.connect.ChatSession) {
      console.log("CDEBUG ===> Subscribing to Connect Contact Events for chats");
      window.connect.contact(contact => {

        // This is invoked when CCP is ringing
        contact.onConnecting(() => {
          console.log("CDEBUG ===> onConnecting() >> contactId: ", contact.contactId);
          let contactAttributes = contact.getAttributes();
          console.log("CDEBUG ===> contactAttributes: ", JSON.stringify(contactAttributes));
          let contactQueue = contact.getQueue();
          console.log("CDEBUG ===> contactQueue: ", contactQueue);
        });

        // This is invoked when the chat is accepted
        contact.onAccepted(async () => {
          console.log("CDEBUG ===> onAccepted: ", contact);
          const cnn = contact.getConnections().find(cnns => cnns.getType() === window.connect.ConnectionType.AGENT);
          const agentChatSession = await cnn.getMediaController();
          setCurrentContactId(contact.contactId)
          console.log("CDEBUG ===> agentChatSession ", agentChatSession)

          // Save the session to props, this is required to send messages within the chatroom.js
          setAgentChatSessionState(agentChatSessionStates => [...agentChatSessionStates, { [contact.contactId]: agentChatSession }])
          // Get the language from the attributes, if the value is valid then add to the store
          languageTranslate[contact.contactId] = contact.getAttributes().PrefLang.value;
          setLanguageTranslate(languageTranslate);
          console.log("CDEBUG ===> onAccepted, languageTranslate ", languageTranslate)

        });

        // This is invoked when the customer and agent are connected
        contact.onConnected(async () => {
          console.log("CDEBUG ===> onConnected() >> contactId: ", contact.contactId);
          const cnn = contact.getConnections().find(cnns => cnns.getType() === window.connect.ConnectionType.AGENT);
          const agentChatSession = await cnn.getMediaController();
          GetEvents(contact, languageTranslate, agentChatSession);

        });

        // This is invoked when new agent data is available
        contact.onRefresh(() => {
          console.log("CDEBUG ===> onRefresh() >> contactId: ", contact.contactId);
        });

        // This is invoked when the agent moves to ACW
        contact.onEnded(() => {
          console.log("CDEBUG ===> onEnded() >> contactId: ", contact.contactId);
        });

        // This is invoked when the agent moves out of ACW to a different state
        contact.onDestroy(() => {
          console.log("CDEBUG ===> onDestroy() >> contactId: ", contact.contactId);
          // TODO need to remove the previous chats from the store
          //clearChat()
          setCurrentContactId('');
        });
      });

      /* 
      **** Subscribe to the agent API **** 
      See : https://github.com/aws/amazon-connect-streams/blob/master/Documentation.md
      */

      console.log("CDEBUG ===> Subscribing to Connect Agent Events");
      window.connect.agent((agent) => {
        agent.onStateChange((agentStateChange) => {
          // On agent state change, update the React state.
          let state = agentStateChange.newState;
          console.log("CDEBUG ===> New State: ", state);

        });

      });
    }
    else {
      console.log("CDEBUG ===> waiting 3s");
      setTimeout(function () { subscribeConnectEvents(); }, 3000);
    }
  }, [languageTranslate]);


  // ***** 
  // Loading CCP
  // *****
  useEffect(() => {
    window.connect.agentApp.initApp(
      "ccp",
      "ccp-container",
      CONNECTURL + "/connect/ccp-v2/", {
      ccpParams: {
        region: REGION,
        pageOptions: {                  // optional
          enableAudioDeviceSettings: true, // optional, defaults to 'false'
          enablePhoneTypeSettings: true // optional, defaults to 'true'
        },
        loginPopup: true,
      }
    }
    );
    subscribeConnectEvents();
  }, [subscribeConnectEvents]);

  return (
    <main>
      {/* grid-container */}
      <div id="grid-container">

        <div className="row">
          {/* CCP window will load here */}
          <div className="col-md-6">
            <div id="ccp-container"></div>
          </div>


          {/* Translate window will laod here. We pass the agent state to be able to use this to push messages to CCP */}
          <div className="col-md-6">
            <div id="chatroom" ><Chatroom session={agentChatSessionState} /> </div>
          </div>
        </div>


      </div>
    </main>
  );
};

export default CCP;
